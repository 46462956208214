import { lazy } from 'react'
import { IRoutes } from './types'
import Home from 'routes/Home'
import Unknown from 'routes/Unknown'

//Страницы первого уровня
export const routes: IRoutes[] = [
   {
      path: '/',
      title: 'Главная',
      access: false,
      Element: Home
   },
   {
      path: '/', //Daiquiri
      title: 'Регистрация аккаунта',
      access: true,
      Element: lazy(() => import('routes/Information'))
   },
   {
      path: '/additional-education',
      title: 'Портфолио АЛГОРИТМ 23',
      access: true,
      Element: lazy(() => import('routes/AdditionalEducation'))
   },
   {
      path: '/contacts',
      title: 'Контакты ',
      access: false,
      Element: lazy(() => import('routes/Contacts'))
   },
   // Страницы второго уровня (Основные сведения)
   {
      path: '/top',
      title: 'Топ игроки сервера',
      access: true,
      Element: lazy(() => import('routes/Top'))
   },
   {
      path: '/structure',
      title: 'Скачать клиент игры',
      access: true,
      Element: lazy(() => import('routes/Structure'))
   },
   {
      path: '/organization-documents',
      title: 'Личный кабинет',
      access: true,
      Element: lazy(() => import('routes/OrganizationDocuments'))
   },
   {
      path: '/education',
      title: 'Поддержка игроков',
      access: true,
      Element: lazy(() => import('routes/Education'))
   },
   {
      path: '/educational-standards',
      title: 'Система донатов',
      access: true,
      Element: lazy(() => import('routes/EducationalStandards'))
   },
   {
      path: '/personal',
      title: 'Преподавательский состав Медколледжа РУТ (МИИТ)',
      access: true,
      Element: lazy(() => import('routes/Personal'))
   },
   {
      path: '/material-technical-support',
      title: 'Материально-техническое обеспечение и оснащенность образовательного процесса',
      access: true,
      Element: lazy(() => import('routes/MaterialTechnicalSupport'))
   },
   {
      path: '/paid-educational-services',
      title: 'Платные образовательные услуги',
      access: true,
      Element: lazy(() => import('routes/PaidEducationalservices'))
   },
   {
      path: '/vacancies-for-admission',
      title: 'Вакантные места для приема (перевода)',
      access: true,
      Element: lazy(() => import('routes/VacanciesAdmission'))
   },
   {
      path: '/scholarship-material-support',
      title: 'Стипендия и иные виды материальной поддержки',
      access: true,
      Element: lazy(() => import('routes/Scholarship'))
   },
   //Страницы третьего уровня (Персонал)
   {
      path: '/staff/ageeva',
      title: 'Агеева Елена Викторовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ageeva'))
   },
   {
      path: '/staff/bakanova',
      title: 'Баканова Анастасия Сергеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Bakanova'))
   },
   {
      path: '/staff/bogatkova',
      title: 'Богаткова Тамара Алексеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Bogatkova'))
   },
   {
      path: '/staff/budanseva',
      title: 'Буданцева Светлана Васильевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Budanseva'))
   },
   {
      path: '/staff/chinchikova',
      title: 'Чинчикова Анна Геннадьевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Chinchikova'))
   },
   {
      path: '/staff/demidovish',
      title: 'Демидович Оксана Григорьевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Demidovish'))
   },
   {
      path: '/staff/demkina',
      title: 'Дёмкина Елена Васильевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Demkina'))
   },
   {
      path: '/staff/director',
      title: 'Кузнецова Вера Николаевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Director'))
   },
   {
      path: '/staff/dmitrieva',
      title: 'Дмитриева Ольга Михайловна',
      access: true,
      Element: lazy(() => import('routes/Staff/Dmitrieva'))
   },
   {
      path: '/staff/doronin',
      title: 'Доронин Антон Юрьевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Doronin'))
   },
   {
      path: '/staff/efimova',
      title: 'Ефимова Елена Ивановна',
      access: true,
      Element: lazy(() => import('routes/Staff/Efimova'))
   },
   {
      path: '/staff/emelyanova',
      title: 'Емельянова Галина Анатольевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Emelyanova'))
   },
   {
      path: '/staff/ermilova',
      title: 'Ермилова Алла Борисовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ermilova'))
   },
   {
      path: '/staff/evseev',
      title: 'Евсеев Борис Юрьевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Evseev'))
   },
   {
      path: '/staff/fomina',
      title: 'Фомина Любовь Леонидовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ageeva'))
   },
   {
      path: '/staff/gesse',
      title: 'Гессе Ольга Борисовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Gesse'))
   },
   {
      path: '/staff/gorh',
      title: 'Горх Фарида Фаритовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Gorh'))
   },
   {
      path: '/staff/ivanova',
      title: 'Иванова Лариса Николаевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ivanova'))
   },
   {
      path: '/staff/ivanova-nadezhda',
      title: 'Иванова Надежда Александровна',
      access: true,
      Element: lazy(() => import('routes/Staff/IvanovaNadezhda'))
   },
   {
      path: '/staff/ivanycheva',
      title: 'Иванычева Мария Александровна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ivanycheva'))
   },
   {
      path: '/staff/kaminskay',
      title: 'Каминская Татьяна Ивановна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kaminskay'))
   },
   {
      path: '/staff/kislitsyna',
      title: 'Кислицына Лилия Яковлевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kislitsyna'))
   },
   {
      path: '/staff/kolesnichenko',
      title: 'Колесниченко Лидия Глебовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kolesnichenko'))
   },
   {
      path: '/staff/kocharyan',
      title: 'Кочарян Лилия Фаритовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kocharyan'))
   },
   {
      path: '/staff/kolotilshchikova',
      title: 'Каминская Татьяна Ивановна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kolotilshchikova'))
   },
   {
      path: '/staff/kostenko',
      title: 'Костенко Галина Семеновна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kostenko'))
   },
   {
      path: '/staff/kovalevskaya',
      title: 'Ковалевская Екатерина Михайловна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kovalevskaya'))
   },
   {
      path: '/staff/krymtseva',
      title: 'Крымцева Татьяна Алексеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Krymtseva'))
   },
   {
      path: '/staff/kuratseva',
      title: 'Куратцева Ирина Николаевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kuratseva'))
   },
   {
      path: '/staff/kuznetsov',
      title: 'Кузнецов Николай Анатольевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Kuznetsov'))
   },
   {
      path: '/staff/kuznetsova',
      title: 'Кузнецова Надежда Васильевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Kuznetsova'))
   },
   {
      path: '/staff/lapina',
      title: 'Лапина Надежда Викторовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Lapina'))
   },
   {
      path: '/staff/malenko',
      title: 'Маленко Наталья Сергеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Malenko'))
   },
   {
      path: '/staff/malyshenko',
      title: 'Малышенко Игорь Александрович',
      access: true,
      Element: lazy(() => import('routes/Staff/Malyshenko'))
   },
   {
      path: '/staff/Markelov',
      title: 'Маркелов Владимир Иванович',
      access: true,
      Element: lazy(() => import('routes/Staff/Markelov'))
   },
   {
      path: '/staff/markelova',
      title: 'Маркелова Ксения Владимировна',
      access: true,
      Element: lazy(() => import('routes/Staff/Markelova'))
   },
   {
      path: '/staff/melnikov',
      title: 'Мельников Иван Витальевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Melnikov'))
   },
   {
      path: '/staff/mishon',
      title: 'Мишон Надежда Владимировна',
      access: true,
      Element: lazy(() => import('routes/Staff/Mishon'))
   },
   {
      path: '/staff/murashova',
      title: 'Мурашова Ирина Владимировна',
      access: true,
      Element: lazy(() => import('routes/Staff/Murashova'))
   },
   {
      path: '/staff/nazarenko',
      title: 'Назаренко Наталья Валерьевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Nazarenko'))
   },
   {
      path: '/staff/nukulina',
      title: 'Никулина Ольга Ивановна',
      access: true,
      Element: lazy(() => import('routes/Staff/Nukulina'))
   },
   {
      path: '/staff/ozerova',
      title: 'Озерова Наталья Васильевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ozerova'))
   },
   {
      path: '/staff/petrova',
      title: 'Петрова Татьяна Николаевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Petrova'))
   },
   {
      path: '/staff/pitirimov',
      title: 'Питиримов Иван Иванович',
      access: true,
      Element: lazy(() => import('routes/Staff/Pitirimov'))
   },
   {
      path: '/staff/platovets',
      title: 'Павлов-Пластовец Павел Владимирович',
      access: true,
      Element: lazy(() => import('routes/Staff/Platovets'))
   },
   {
      path: '/staff/pushkova',
      title: 'Пучкова Ольга Ильинична',
      access: true,
      Element: lazy(() => import('routes/Staff/Pushkova'))
   },
   {
      path: '/staff/rusanov',
      title: 'Русанов Денис Дмитриевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Rusanov'))
   },
   {
      path: '/staff/safonova',
      title: 'Сафонова Ольга Анатольевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Safonova'))
   },
   {
      path: '/staff/savitskaya',
      title: 'Савицкая Ирина Игоревна',
      access: true,
      Element: lazy(() => import('routes/Staff/Savitskaya'))
   },
   {
      path: '/staff/ryabtseva',
      title: 'Рябцева Екатерина Дмитриевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Ryabtseva'))
   },
   {
      path: '/staff/serebryakova',
      title: 'Серебрякова Ольга Викторовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Serebryakova'))
   },
   {
      path: '/staff/shalimova',
      title: 'Шалимова Валентина Григорьевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Shalimova'))
   },
   {
      path: '/staff/sharypova',
      title: 'Шарыпова Татьяна Васильевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Sharypova'))
   },
   {
      path: '/staff/shipileva',
      title: 'Шпилева Наталия Федоровна',
      access: true,
      Element: lazy(() => import('routes/Staff/Shipileva'))
   },
   {
      path: '/staff/shokot',
      title: 'Шокот Ольга Викторовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Shokot'))
   },
   {
      path: '/staff/shvets',
      title: 'Швец Любовь Игоревна',
      access: true,
      Element: lazy(() => import('routes/Staff/Shvets'))
   },
   {
      path: '/staff/sidorova',
      title: 'Сидорова Татьяна Александровна',
      access: true,
      Element: lazy(() => import('routes/Staff/Sidorova'))
   },
   {
      path: '/staff/shykova',
      title: 'Жукова Евгения Анатольевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Shykova'))
   },
   {
      path: '/staff/sychev',
      title: 'Сычев Григорий Сергеевич',
      access: true,
      Element: lazy(() => import('routes/Staff/Sychev'))
   },
   {
      path: '/staff/timerova',
      title: 'Тимерова Римма Рудольфовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Timerova'))
   },
   {
      path: '/staff/tireshko',
      title: 'Тирешко Яна Викторовна',
      access: true,
      Element: lazy(() => import('routes/Staff/Tireshko'))
   },
   {
      path: '/staff/Vasilenko',
      title: 'Василенко Светлана Алексеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Vasilenko'))
   },
   {
      path: '/staff/tomskay',
      title: 'Томская Татьяна Николаевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Tomskay'))
   },
   {
      path: '/staff/yarovikova',
      title: 'Яровикова Светлана Сергеевна',
      access: true,
      Element: lazy(() => import('routes/Staff/Yarovikova'))
   },
   // Страницы второго уровня (Абитуриентам)
   {
      path: '/medspecialties',
      title: 'Специальности',
      access: true,
      Element: lazy(() => import('routes/Medspecialties'))
   },
   //Страницы третьего уровня (Абитуриентам)
   {
      path: '/orthopedic-dentistry',
      title: 'Стоматология ортопедическая',
      access: true,
      Element: lazy(() => import('routes/OrthopedicDentistry'))
   },
   {
      path: '/nursing',
      title: 'Сестринское дело',
      access: true,
      Element: lazy(() => import('routes/Nursing'))
   },
   {
      path: '/medical-business',
      title: 'Лечебное дело',
      access: true,
      Element: lazy(() => import('routes/MedicalBusiness'))
   },
   //Страницы второго уровня (Абитуриентам)
   {
      path: '/request',
      title: 'Подача заявления',
      access: true,
      Element: lazy(() => import('routes/Request'))
   },
   {
      path: '/tests',
      title: 'Вступительные испытания',
      access: true,
      Element: lazy(() => import('routes/Tests'))
   },
   {
      path: '/medrequirement',
      title: 'Требование к медицинской документации',
      access: true,
      Element: lazy(() => import('routes/Medrequirement'))
   },
   {
      path: '/budget-basis',
      title: 'Каталог продуктов 1С',
      access: true,
      Element: lazy(() => import('routes/Budget'))
   },
   {
      path: '/medical-contraindications',
      title: 'Медицинские противопоказания',
      access: true,
      Element: lazy(() => import('routes/MedicalContraindications'))
   },
   {
      path: '/pricelist',
      title: 'Стоимость оказания услуг ',
      access: true,
      Element: lazy(() => import('routes/Price'))
   },
   {
      path: '/frequently-asked-questions', // Daiquiri - вопросы и ответы //
      title: 'Вопросы и ответы',
      access: true,
      Element: lazy(() => import('routes/Frequently'))
   },
   {
      path: '/admissions-schedule',
      title: 'График работы приемной комиссии',
      access: true,
      Element: lazy(() => import('routes/AdmissionsSchedule'))
   },
   {
      path: '/doors-open-days',
      title: 'Дни открытых дверей',
      access: true,
      Element: lazy(() => import('routes/OpenDays'))
   },
   {
      path: '/admissions-question',
      title: 'Задать вопрос приемной комиссии',
      access: true,
      Element: lazy(() => import('routes/AdmissionsQuestion'))
   },
   // Страницы второго уровня (Обучение студентов)
   {
      path: '/grounds-transfer',
      title: 'Порядок и основания перевода, отчисления и восстановления обучающихся',
      access: true,
      Element: lazy(() => import('routes/GroundsTransfer'))
   },
   {
      path: '/student-life',
      title: 'Студенческая жизнь (информация о студ.совете)',
      access: true,
      Element: lazy(() => import('routes/StudentLife'))
   },
   {
      path: '/accreditation',
      title: 'Первичная аккредитация',
      access: true,
      Element: lazy(() => import('routes/Accreditation'))
   },
   {
      path: '/ebs-student',
      title: 'ЭБС студента',
      access: true,
      Element: lazy(() => import('routes/EbsStudent'))
   },
   {
      path: '/distance-learning',
      title: 'Дистанционное обучение',
      access: true,
      Element: lazy(() => import('routes/DistanceLearning'))
   },
   {
      path: '/internship',
      title: 'Производственная практика',
      access: true,
      Element: lazy(() => import('routes/Internship'))
   },
   {
      path: '/doc-oformlenie',
      title: 'Указания по оформлению',
      access: true,
      Element: lazy(() => import('routes/DocOformlenie'))
   },
   {
      path: '/college-video',
      title: 'Видео колледжа',
      access: true,
      Element: lazy(() => import('routes/CollegeVideo'))
   },
   {
      path: '/debt',
      title: 'Табель успеваемости студентов',
      access: true,
      Element: lazy(() => import('routes/Debt'))
   },
   {
      path: '/psychologist',
      title: 'Кабинет педагога-психолога',
      access: true,
      Element: lazy(() => import('routes/Psychologist'))
   },
   {
      path: '/electronic-journal',
      title: 'Электронный журнал',
      access: true,
      Element: lazy(() => import('routes/ElectronicJournal'))
   },
   {
      path: '/unloading',
      title: 'Выгрузка работ',
      access: true,
      Element: lazy(() => import('routes/ElectronicJournal'))
   },
   {
      path: '/med-test',
      title: 'Тестирование студентов',
      access: true,
      Element: lazy(() => import('routes/MedTest'))
   },
   {
      path: '/group-lists',
      title: 'Списки групп колледжа',
      access: true,
      Element: lazy(() => import('routes/GroupLists'))
   },
   {
      path: '/schedule',
      title: 'Расписание занятий',
      access: true,
      Element: lazy(() => import('routes/Schedule'))


   },
   // Страницы третьего уровня (Расписание)
   {
      path: '/mld-practice',
      title: 'Практическое расписание - МЛД',
      access: true,
      Element: lazy(() => import('routes/MldPractice'))
   },
   {
      path: '/msd-practice',
      title: 'Практическое расписание - МCД',
      access: true,
      Element: lazy(() => import('routes/MsdPractice'))
   },
   {
      path: '/msv-practice',
      title: 'Практическое расписание - МCВ',
      access: true,
      Element: lazy(() => import('routes/MsvPractice'))
   },
   {
      path: '/msd',
      title: 'Расписание - МCД',
      access: true,
      Element: lazy(() => import('routes/Msd'))
   },
   {
      path: '/mld',
      title: 'Расписание - МЛД',
      access: true,
      Element: lazy(() => import('routes/Mld'))
   },
   {
      path: '/mso',
      title: 'Расписание - МCО',
      access: true,
      Element: lazy(() => import('routes/Mso'))
   },
   {
      path: '/msv',
      title: 'Расписание - МCВ',
      access: true,
      Element: lazy(() => import('routes/Msv'))
   },
   {
      path: '/gia',
      title: 'Расписание - МCВ',
      access: true,
      Element: lazy(() => import('routes/Gia'))
   },
   // Страницы второго уровня (Преподавателям)
   {
      path: '/teacher-certification',
      title: 'Аттестация преподавателей',
      access: true,
      Element: Unknown
   },
   {
      path: '/cycle-commissions',
      title: 'Цикловые комиссии',
      access: true,
      Element: Unknown
   },
   {
      path: '/work-plans',
      title: 'План работ Колледжа',
      access: true,
      Element: Unknown
   },
   {
      path: '/school-teaching-excellence',
      title: 'Школа педагогического мастерства',
      access: true,
      Element: Unknown
   },
   {
      path: '/like-link',
      title: 'Полезные ссылки',
      access: true,
      Element: Unknown
   },
   //Страницы второго уровня (Портфолио)
   {
      path: '/blog',
      title: 'Наши услуги',
      access: true,
      Element: lazy(() => import('routes/Blog'))
   },
   {
      path: '/photo-gallery',
      title: 'Особенности игры',
      access: true,
      Element: lazy(() => import('routes/Photogallyrey'))
   },
   {
      path: '/about-college',
      title: 'О колледже',
      access: true,
      Element: lazy(() => import('routes/HistoryCollege'))
   },

    // Страницы второго уровня (ДПО)
   {
      path: '/medical-examinations',
      title: 'Проведения предрейсовых медосмотров водителей автотранспортных средств',
      access: true,
      Element: lazy(() => import('routes/MedicalExaminations'))
   },
   {
      path: '/laboratory-diagnostics',
      title: 'Современные методы клинических исследований в лабораторной диагностике',
      access: true,
      Element: lazy(() => import('routes/LaboratoryDiagnostics'))
   },
   {
      path: '/aspects-orthopedic',
      title: 'Современные аспекты ортопедической помощи населению',
      access: true,
      Element: lazy(() => import('routes/AspectsOrthopedic'))
   },
   {
      path: '/medical-massage',
      title: 'Медицинский массаж',
      access: true,
      Element: lazy(() => import('routes/MedicalMassage'))
   },
   {
      path: '/anaesthesiology-and-resuscitation',
      title: 'Современные аспекты сестринского дела в анестезиологии и реаниматологии',
      access: true,
      Element: lazy(() => import('routes/AnaesthesiologyResuscitation'))
   },
   {
      path: '/nursing-in-dentistry',
      title: 'Сестринское дело в стоматологии',
      access: true,
      Element: lazy(() => import('routes/NursingDentistry'))
   },
   {
      path: '/nursing-in-surgery',
      title: 'Сестринское дело в хирургии',
      access: true,
      Element: lazy(() => import('routes/NursingSurgery'))
   },
   {
      path: '/nursing-in-therapy',
      title: 'Сестринское дело в терапии',
      access: true,
      Element: lazy(() => import('routes/NursingTherapy'))
   },
   {
      path: '/health-protection',
      title: 'Охрана здоровья работников промышленных и других предприятий',
      access: true,
      Element: lazy(() => import('routes/HealthProtection'))
   },
     //Страницы второго уровня (Портфолио)
     {
      path: '/blog',
      title: 'Команда разработчиков Daiquiri',
      access: true,
      Element: lazy(() => import('routes/Blog'))
   },
   {
      path: '/photo-gallery',
      title: 'Фотогалерея',
      access: true,
      Element: lazy(() => import('routes/Photogallyrey'))
   },
   {
      path: '/about-college',
      title: 'История команды Daiquiri',
      access: true,
      Element: lazy(() => import('routes/HistoryCollege'))
   },

    // Страницы второго уровня (Список групп колледжа)
    //МСД - группы
   {
      path: '/msd-111',
      title: 'Список группы МСД-111',
      access: true,
      Element: lazy(() => import('routes/Msd111'))
   },
   {
      path: '/msd-112',
      title: 'Список группы МСД-112',
      access: true,
      Element: lazy(() => import('routes/Msd112'))
   },
   {
      path: '/msd-113',
      title: 'Список группы МСД-113',
      access: true,
      Element: lazy(() => import('routes/Msd113'))
   },
   {
      path: '/msd-114',
      title: 'Список группы МСД-114',
      access: true,
      Element: lazy(() => import('routes/Msd114'))
   },
   {
      path: '/msd-115',
      title: 'Список группы МСД-115',
      access: true,
      Element: lazy(() => import('routes/Msd115'))
   },
   {
      path: '/msd-116',
      title: 'Список группы МСД-116',
      access: true,
      Element: lazy(() => import('routes/Msd116'))
   },
   {
      path: '/msd-211',
      title: 'Список группы МСД-211',
      access: true,
      Element: lazy(() => import('routes/Msd211'))
   },
   {
      path: '/msd-212',
      title: 'Список группы МСД-212',
      access: true,
      Element: lazy(() => import('routes/Msd212'))
   },
   {
      path: '/msd-213',
      title: 'Список группы МСД-213',
      access: true,
      Element: lazy(() => import('routes/Msd213'))
   },
   {
      path: '/msd-214',
      title: 'Список группы МСД-214',
      access: true,
      Element: lazy(() => import('routes/Msd214'))
   },
   {
      path: '/items-base-r2',
      title: 'База итемов R2:Online',
      access: true,
      Element: lazy(() => import('routes/BaseItems'))
   },
   {
      path: '/calendar-events',
      title: 'Календарь событий',
      access: true,
      Element: lazy(() => import('routes/CalendarEvents'))
   },
   {
      path: '/epic-quests',
      title: 'Эпические квесты',
      access: true,
      Element: lazy(() => import('routes/EpicQuests'))
   },
   {
      path: '/pets',
      title: 'Питомцы',
      access: true,
      Element: lazy(() => import('routes/Pets'))
   },
   {
      path: '/arena',
      title: 'Арена (Командные битвы)',
      access: true,
      Element: lazy(() => import('routes/Arena'))
   },
   {
      path: '/special-skills',
      title: 'Список группы МСД-314',
      access: true,
      Element: lazy(() => import('routes/SpecialSkills'))
   },
   {
      path: '/achievements-and-trophies',
      title: 'Достижения и трофеи',
      access: true,
      Element: lazy(() => import('routes/AchievementsTrophies'))
   },
   {
      path: '/game-trials',
      title: 'Испытания',
      access: true,
      Element: lazy(() => import('routes/GameTrials'))
   },
   {
      path: '/fortress-sieges',
      title: 'Список группы МСД-411',
      access: true,
      Element: lazy(() => import('routes/FortressSieges'))
   },
   {
      path: '/runes',
      title: 'Руны',
      access: true,
      Element: lazy(() => import('routes/Runes'))
   },
   {
      path: '/strengthening-items',
      title: 'Усиление предметов',
      access: true,
      Element: lazy(() => import('routes/StrengtheningItems'))
   },
   {
      path: '/skills',
      title: 'Умения',
      access: true,
      Element: lazy(() => import('routes/Skills'))
   },
   {
      path: '/group',
      title: 'Отряд (группа)',
      access: true,
      Element: lazy(() => import('routes/Group'))
   },
   {
      path: '/limit',
      title: 'Вес и сытость',
      access: true,
      Element: lazy(() => import('routes/Limit'))
   },
   //МЛД - группы
   {
      path: '/mld-111',
      title: 'Список группы МЛД-111',
      access: true,
      Element: lazy(() => import('routes/Mld111'))
   },
   {
      path: '/mld-112',
      title: 'Список группы МЛД-112',
      access: true,
      Element: lazy(() => import('routes/Mld112'))
   },
   {
      path: '/mld-211',
      title: 'Список группы МЛД-211',
      access: true,
      Element: lazy(() => import('routes/Mld211'))
   },
   {
      path: '/mld-212',
      title: 'Список группы МЛД-212',
      access: true,
      Element: lazy(() => import('routes/Mld212'))
   },
   {
      path: '/mld-311',
      title: 'Список группы МЛД-311',
      access: true,
      Element: lazy(() => import('routes/Mld311'))
   },
   {
      path: '/mld-312',
      title: 'Список группы МЛД-312',
      access: true,
      Element: lazy(() => import('routes/Mld312'))
   },
   {
      path: '/mld-313',
      title: 'Список группы МЛД-313',
      access: true,
      Element: lazy(() => import('routes/Mld313'))
   },
   {
      path: '/mld-411',
      title: 'Список группы МЛД-411',
      access: true,
      Element: lazy(() => import('routes/Mld411'))
   },
   {
      path: '/mld-412',
      title: 'Список группы МЛД-412',
      access: true,
      Element: lazy(() => import('routes/Mld412'))
   },
   //МСО - группы
   {
      path: '/guild',
      title: 'Гильдия',
      access: true,
      Element: lazy(() => import('routes/Guild'))
   },
   {
      path: '/chat',
      title: 'Чат',
      access: true,
      Element: lazy(() => import('routes/Chat'))
   },
   {
      path: '/battle',
      title: 'Битва',
      access: true,
      Element: lazy(() => import('routes/Battle'))
   },
   {
      path: '/using-shop',
      title: 'Использование торговой лавки',
      access: true,
      Element: lazy(() => import('routes/UsingShop'))
   },
   {
      path: '/controls-and-Hotkeys',
      title: 'Управление и горячие клавиши',
      access: true,
      Element: lazy(() => import('routes/ControlsHotkeys'))
   },
   {
      path: '/items',
      title: 'Предметы',
      access: true,
      Element: lazy(() => import('routes/Items'))
   },
   //МСВ - Группы
   {
      path: '/registration',  //Daiquiri
      title: 'Регистрация',
      access: true,
      Element: lazy(() => import('routes/Registration'))
   },
   {
      path: '/server-description', //Daiquiri
      title: 'Описание серверов Daiquiri',
      access: true,
      Element: lazy(() => import('routes/ServerDescription'))
   },
   {
      path: '/class-r2', //Daiquiri
      title: 'Классы',
      access: true,
      Element: lazy(() => import('routes/ClassR2'))
   },
   {
      path: '/game-mechanics-r2', //Daiquiri
      title: 'Игровые механики',
      access: true,
      Element: lazy(() => import('routes/GameMechanics'))
   },
   {
      path: '/download-r2', //Daiquiri
      title: 'Скачать R2:Online - Daiquiri',
      access: true,
      Element: lazy(() => import('routes/Download'))
   },
   {
      path: '/project-rules',  //Daiquiri
      title: 'Правила проекта',
      access: true,
      Element: lazy(() => import('routes/ProjectRules'))
   },
   // Страницы обслуживания 
   {
      path: '*',
      title: '404',
      access: true,
      Element: Unknown
   }
]
